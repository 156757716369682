// FontContext.tsx
import React, { createContext, useState } from 'react';

type FontContextType = {
  currentFont: string;
  setCurrentFont: React.Dispatch<React.SetStateAction<string>>;
};

const FontContext = createContext<FontContextType | undefined>(undefined);

type FontProviderProps = {
  children: React.ReactNode;
};

const FontProvider: React.FC<FontProviderProps> = ({ children }) => {
  const [currentFont, setCurrentFont] = useState('--font-knightwarrior'); // Default font variable

  return (
    <FontContext.Provider value={{ currentFont, setCurrentFont }}>
      {children}
    </FontContext.Provider>
  );
};

export { FontContext, FontProvider };

// Tricks.tsx
import React from 'react';

type TricksProps = {
    setShowTricks: React.Dispatch<React.SetStateAction<boolean>>;
    currentFont: string;
};

const Tricks: React.FC<TricksProps> = ({ setShowTricks, currentFont }) => {
    const handleClose = () => {
        setShowTricks(false);
    };

    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className={`bg-white w-4/5 h-4/5 p-6 rounded relative ${currentFont}`}>
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
                    onClick={handleClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
                <h2 className="text-xl font-bold mb-4">Tricks</h2>
                <div className="flex flex-col">
                    <p>Bla bla bla...</p>
                </div>
            </div>
        </div>
    );
};

export default Tricks;
// Login2.tsx
import React, { useState, useContext } from 'react';
import { signInWithCustomToken } from "firebase/auth";
import { auth, db } from '../firebase'; // Import auth and db
import { doc, getDoc } from 'firebase/firestore';
import { User } from './types';
import UserContext from './misc/UserContext';

type Login2Props = {
  email: string;
  setShowLogin2: React.Dispatch<React.SetStateAction<boolean>>;
  setShowLogin3: React.Dispatch<React.SetStateAction<boolean>>;
  setUserData: React.Dispatch<React.SetStateAction<User | null>>;
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  currentFont: string;
  showLogin3: boolean;
  handleUserSession: (email: string, token: string, user: User) => void;
};

const Login2: React.FC<Login2Props> = ({ email, setShowLogin2, setShowLogin3, setUserData, setShowLogin, currentFont, handleUserSession }) => {
  const [otp, setOtp] = useState('');
  const userContext = useContext(UserContext);
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleVerify = async () => {
    const formattedEmail = email.toLowerCase();
    try {
      const response = await fetch('http://localhost:5001/dahdooh/europe-west2/verifyOTP', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formattedEmail, otp }),
      });

      const data = await response.json();
      if (data.token) {
        const userDoc = await getDoc(doc(db, "users", formattedEmail));
        if (userDoc.exists()) {
          const fetchedUserData = userDoc.data() as User;
          console.log('User data retrieved from the database:', fetchedUserData); // Log the retrieved user data

          setUserData(fetchedUserData);

          // Call handleUserSession function from App.tsx
          console.log(`Calling handleUserSession with email: ${formattedEmail} and token: ${data.token}`);
          await handleUserSession(formattedEmail, data.token, fetchedUserData);

          setShowLogin3(true);
          setShowLogin(false);
          setShowLogin2(false);
        } else {
          console.error('OTP Verification failed, no token received.');
          setOtpAttempts(otpAttempts + 1);
          setErrorMessage('Invalid OTP. Please try again.');
          if (otpAttempts >= 5) {
            setShowLogin2(false);
          }
        }
      } else {
        console.error('OTP Verification failed, no token received.');
        setShowLogin2(false);
      }
    } catch (error) {
      console.error('Error in handleVerify:', error);
      setShowLogin2(false);
    }
  };

  const handleClose = () => setShowLogin2(false);

    return (
      <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50 ${currentFont}`}>
        <div className="bg-white w-4/6 p-6 rounded relative">
          <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-xl font-bold mb-4">OTP Verification</h2>
          <div className="flex flex-col">
            <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="OTP" className="border rounded px-4 py-2 mb-4" />
            <button onClick={handleVerify} className="bg-blue-500 text-white rounded px-4 py-2">Verify</button>
          </div>
        </div>
      </div>
    );
};

export default Login2;

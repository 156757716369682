import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { app } from './firebase';

interface AuthContextInterface {
  user: User | null;
  showLogin: () => void;
  showLogout: () => void;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoggingIn(false);
    });
    return unsubscribe;
  }, []);

  const showLogin = () => setIsLoggingIn(true);
  const showLogout = () => {
    const auth = getAuth(app);
    signOut(auth);
  };

  return (
    <AuthContext.Provider value={{ user, showLogin, showLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Register2 from './Register2';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { User } from './types';

type RegisterProps = {
  setShowRegister: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegister3: React.Dispatch<React.SetStateAction<boolean>>;
  currentFont: string;
  setupAuthStateObserver: () => void;
  isUserDataFetched: boolean;
  handleUserSession: (userEmail: string, token: string, user: User) => Promise<void>;
};

const Register: React.FC<RegisterProps> = ({ setShowRegister, setShowRegister3, currentFont, setupAuthStateObserver, isUserDataFetched, handleUserSession}) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showRegister2, setShowRegister2] = useState(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  

  const handleRegister = async () => {
    
    // Validate reCAPTCHA
    if (!captchaRef.current?.getValue() || !email || !name || !gender) {
      setEmailError('Please complete all fields.');
      return;
    }

    // Email format validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError('Invalid email format.');
      return;
    }

    // Convert the email to lowercase
    const formattedEmail = email.toLowerCase();

    // Check if email already exists
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", formattedEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      setEmailError('Email already registered.');
      return;
    }

    // Clear email error
    setEmailError('');

    // Request server to generate OTP
    const response = await fetch('http://localhost:5001/dahdooh/europe-west2/api/generateOTP', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });
    
    const responseData = await response.json();
    if (responseData.message === 'OTP sent to email') {
      setShowRegister2(true);
    } else {
      setEmailError(responseData.error || 'Error generating OTP.');
    }
  }; // This is where handleRegister function ends

  const handleClose = () => setShowRegister(false);

  return (
    <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50 ${currentFont}`}>
      <div className="bg-white w-4/6 p-6 rounded relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4">Register</h2>
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 mb-4"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 mb-4"
          />
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 mb-4"
          >
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
            <ReCAPTCHA
            ref={captchaRef}
            sitekey="6LdR0FAlAAAAAHYzFaJSA58E9EAtBXuyaQeexn0c"
            className="mb-4"
            />
            <button
            onClick={handleRegister}
            className="bg-blue-500 text-white rounded px-4 py-2 mt-4"
            >
            Register
            </button>
            {emailError && <p className="text-red-500">{emailError}</p>} {/* Render the error message */}
        </div>
        </div>
        {showRegister2 && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-white w-4/6 p-6 rounded relative">
            <Register2
                name={name}
                email={email}
                gender={gender}
                setShowRegister={setShowRegister}
                setShowRegister2={setShowRegister2}
                setShowRegister3={setShowRegister3} 
                currentFont={currentFont}
                setUserData={() => {}}  
                handleUserSession={handleUserSession}

            />
            </div>
        </div>
        )}
    </div>
    );
  };
  
export default Register;
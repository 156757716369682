import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { User } from './types';
import Login2 from './Login2'; // Import Login2 component

type LoginProps = {
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
  onRegisterLinkClick: () => void;
  currentFont: string;
  setUserData: React.Dispatch<React.SetStateAction<User | null>>;
  setShowLogin3: React.Dispatch<React.SetStateAction<boolean>>;
  showLogin3: boolean;
  handleUserSession: (email: string, token: string, user: User) => void; // Add this line
};

const Login: React.FC<LoginProps> = ({ setShowLogin, onRegisterLinkClick, currentFont, setUserData, setShowLogin3, showLogin3, handleUserSession }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showLogin2, setShowLogin2] = useState(false);
  const [otpRequestTime, setOtpRequestTime] = useState(0);
  const [otpWaitTime, setOtpWaitTime] = useState(0);
  const [otpTimer, setOtpTimer] = useState(0);
  const [otpAttempts, setOtpAttempts] = useState(0);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      if (otpTimer > 0) {
        setOtpTimer(otpTimer - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [otpTimer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutes and ${remainingSeconds} seconds`;
  };

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const currentTime = Math.floor(Date.now() / 1000);
    if (otpTimer > 0) {
      setEmailError(`Please wait ${formatTime(otpTimer)} before requesting another OTP.`);
      return;
    }

    // Check if the email is in a valid format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return; // Don't proceed with login if the email is invalid
    }

    // Clear the previous error message
    setEmailError('');

    // Convert the email to lowercase
    const formattedEmail = email.toLowerCase();

    // Execute reCAPTCHA
    const captchaValue = captchaRef.current?.getValue();
    if (!captchaValue) {
      // Show an error message or throw an error
      return;
    }

    // Check if the email exists in the database
    const db = getFirestore();
    const q = query(collection(db, "users"), where("email", "==", formattedEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // The email exists in the database
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data() as User;

      // Store the user data for later use
      setUserData(userData);

      // Request server to generate OTP
      const response = await fetch('http://localhost:5001/dahdooh/europe-west2/api/generateOTP', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formattedEmail })
      });

      const responseData = await response.json();
      if (responseData.message === 'OTP sent to email') {
        setShowLogin2(true);
        const newAttempts = otpAttempts + 1;
        setOtpAttempts(newAttempts);
        let newWaitTime;
        switch (newAttempts) {
          case 1: newWaitTime = 60; break; // 1 minute
          case 2: newWaitTime = 300; break; // 5 minutes
          case 3: newWaitTime = 600; break; // 10 minutes
          case 4: newWaitTime = 1800; break; // 30 minutes
          case 5: newWaitTime = 1800; break; // 30 minutes
          default: newWaitTime = 86400; break; // 1 day
        }
        setOtpWaitTime(newWaitTime);
        setOtpTimer(newWaitTime);
      } else {
        setEmailError(responseData.error || 'Error generating OTP.');
      }
    } else {
      setEmailError('This email does not exist.');
    }
    };

  const handleClose = () => setShowLogin(false);

    return (
      <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50 ${currentFont}`}>
        <div className="bg-white w-4/6 p-6 rounded relative">
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <h2 className="text-xl font-bold mb-4">Login</h2>
          <div className="flex flex-col">
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
              className="border border-gray-300 rounded px-4 py-2 mb-4"
            />
            {emailError && <p className="text-red-500">{emailError}</p>}
            <ReCAPTCHA ref={captchaRef} sitekey="6LdR0FAlAAAAAHYzFaJSA58E9EAtBXuyaQeexn0c" />
            <button
              onClick={handleLogin}
              disabled={otpTimer > 0}
              className="bg-blue-500 text-white rounded px-4 py-2"
            >
              Submit
            </button>
            {otpTimer > 0 && <p>You can try again after {formatTime(otpTimer)}.</p>}
            <div className="flex justify-center mt-4">
              <p className="text-sm">
                New users?{' '}
                <a href="#" className="text-blue-500 underline" onClick={onRegisterLinkClick}>
                  Register here
                </a>
              </p>
            </div>
          </div>
        </div>
        {showLogin2 && <Login2 
          email={email} 
          setShowLogin2={setShowLogin2} 
          setShowLogin={setShowLogin} 
          currentFont={currentFont} 
          showLogin3={showLogin3}
          setShowLogin3={setShowLogin3}
          setUserData={setUserData}
          handleUserSession={handleUserSession}
        />}
      </div>
    );
    };

    export default Login;
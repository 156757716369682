import React, { useState, useEffect, useCallback, useTransition, ComponentType } from 'react';
import Level1 from '../../user/Level1';
import Level2 from '../../user/Level2';

interface ProfileProps {
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
  showLevel: number | null;
  setShowLevel: React.Dispatch<React.SetStateAction<number | null>>;
  currentFont: string;
  openLevel: (level: number) => void;
}

type LevelComponentProps = {
  level: number;
  progress: number;
  onPlay: (level: number) => void;
};


const LevelComponent = React.memo<LevelComponentProps>(({ level, progress, onPlay }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', backgroundColor: '#f0f0f0', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', borderRadius: '8px', marginBottom: '10px' }}>
      <p style={{ margin: 0, fontWeight: 'bold' }}>Level {level}</p>
      <p style={{ margin: 0 }}>Progress: {progress}%</p>
      <button
        style={{ padding: '5px 10px', backgroundColor: '#007bff', color: 'white', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
        onClick={() => onPlay(level)}
      >
        Play
      </button>
    </div>
  );
});

// Define a type for the props of your dynamic level components
interface DynamicLevelProps {
  setShowLevel: React.Dispatch<React.SetStateAction<number | null>>;
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
  currentFont: string;
  openScene: (level: number) => void;
}

// Update the DynamicLevel state type to match the expected props
type DynamicLevelComponent = ComponentType<DynamicLevelProps> | null;

const Profile: React.FC<ProfileProps & { showLevel: number | null; setShowLevel: React.Dispatch<React.SetStateAction<number | null>>; }> = ({ setShowProfile, currentFont, showLevel, setShowLevel, openLevel }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progressLevel1, setProgressLevel1] = useState(0);
  const [progressLevel2, setProgressLevel2] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);

  // useEffect for dynamic level import
  const [DynamicLevel, setDynamicLevel] = useState<DynamicLevelComponent>(null);

// Profile.tsx

// ... (other parts of the component)

const playLevel = useCallback((level: number) => {
  setSelectedLevel(level);
  openLevel(level); // openLevel function should handle what happens when a level is selected
}, [openLevel]);


// useEffect for dynamic level import
useEffect(() => {
  if (selectedLevel !== null) {
    import(`../../user/Level${selectedLevel}`)
      .then(component => {
        setDynamicLevel(() => component.default);
      })
      .catch(error => console.error(`Error loading level ${selectedLevel}:`, error));
  }
}, [selectedLevel]);



  
  // useCallback for handleClose
  const handleClose = useCallback(() => {
    setShowProfile(false);
  }, [setShowProfile]); // Correct dependency


  
  

  useEffect(() => {
    console.log('Setting user and loading states');
    const storedUser = localStorage.getItem('user');
    const userData = storedUser ? JSON.parse(storedUser) : null;
    setUser(userData);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>No user data available</div>;
  }

  const { email, name, gender } = user;
  const profileImage = gender === 'male' ? '/assets/img/m.png' : '/assets/img/f.png';

  const profileDetails = (
    <div style={{ backgroundColor: 'lightblue', padding: '15px', display: 'flex', alignItems: 'center', borderRadius: '8px' }}>
      <img src={profileImage} alt="Profile" style={{ width: '60px', height: '60px', borderRadius: '50%', marginRight: '15px' }} />
      <div>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{name || 'No Name'}</p>
        <p style={{ margin: 0 }}>{email}</p>
      </div>
    </div>
  );

  const levels = Array.from({ length: 10 }, (_, i) => i + 1);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className={`bg-white w-4/5 h-4/5 p-6 rounded relative overflow-auto`}>
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
          onClick={handleClose}
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4">Profile</h2>
        <div className="flex flex-col gap-4">
          {profileDetails}
          <div className="mt-4">
          {levels.map(level => (
            <LevelComponent 
              key={level} 
              level={level} 
              progress={level === 1 ? progressLevel1 : (level === 2 ? progressLevel2 : 0)} // Adjust this line as per your logic for progress of each level
              onPlay={playLevel}
            />
          ))}
          </div>
          {DynamicLevel && React.createElement(DynamicLevel, {
  setShowLevel: setShowLevel,
  setShowProfile: setShowProfile,
  currentFont: currentFont,
  openScene: openLevel, // Assuming openLevel is the function to handle scene opening
})}
        </div>
      </div>
    </div>
  );
  
};

export default Profile;

// UserContext.tsx
import React, { Dispatch, SetStateAction } from 'react';
import { User } from '../types'; // Adjust the import path as necessary
import {User as FirebaseUser} from '../../firebase'; // Adjust the import path as necessary
// UserContext.tsx
export type UserContextType = {
    userData: User | null;
    setUserData: Dispatch<SetStateAction<User | null>>;
    firebaseUser: FirebaseUser | null;  // Add firebaseUser to the context
    setFirebaseUser: Dispatch<SetStateAction<FirebaseUser | null>>; // Add setFirebaseUser
  };
  
  const UserContext = React.createContext<UserContextType | null>(null);
  
  export default UserContext;
  

import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';

export function animateCharacter(scene: BABYLON.Scene) {
  // Set the background of the scene to be transparent
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

  // Create a camera
  const camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 5, BABYLON.Vector3.Zero(), scene);

  // Detach the camera control
  camera.detachControl();

  // Create a light
  const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
  light.intensity = 0.7;

  // Load the character model for man1.glb
  BABYLON.SceneLoader.ImportMesh("", "assets/models/", "man1.glb", scene, (newMeshes, particleSystems, skeletons) => {
    const character = newMeshes[0];

    // Scale the character
    character.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

    // Position the character
    character.position = new BABYLON.Vector3(-5, -0.7, 0);

    // Rotate the character mesh around the y-axis by 180 degrees
    character.rotate(BABYLON.Axis.Y, -Math.PI/2, BABYLON.Space.LOCAL);

    // Check if we have animations loaded
    if (skeletons.length > 0 && scene.animationGroups.length > 0) {
      // Find the walking animation group by name
      const walkingAnimGroup = scene.animationGroups.find(ag => ag.name === "walking");

      if (walkingAnimGroup) {
        // Start the walking animation
        walkingAnimGroup.start(true);

        // Update the character's position at the end of each loop to maintain the walking cycle
        walkingAnimGroup.onAnimationGroupLoopObservable.add(() => {
          // Calculate the new position here
          character.position.x += 0.86;

          // Assuming the character crosses the screen when its x position is greater than 10
          if (character.position.x > 6) {
          // Stop the animation
          walkingAnimGroup.stop();
                      // Dispose the first character
                      character.dispose();

          // Load the second character model (man4.glb)
          BABYLON.SceneLoader.ImportMesh("", "assets/models/", "man4.glb", scene, (newMeshes, particleSystems, skeletons) => {
            const character4 = newMeshes[0];
            character4.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);
            character4.position = new BABYLON.Vector3(6, -0.7, 0); // Starting position for man4
            character4.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.LOCAL); // This will make man4 face left

            const walkingAnimGroup4 = scene.animationGroups.find(ag => ag.name === "walkingcarying");
            if (walkingAnimGroup4) {
              walkingAnimGroup4.start(true);

              // Calculate the walking speed based on the animation length and desired travel distance
              const travelDistance = 10; // The distance you want character4 to travel
              const travelSpeed = travelDistance / (walkingAnimGroup4.to - walkingAnimGroup4.from);

              // Define the walking speed
              const walkingSpeed = 0.004; // Set this to the desired speed

              let isWalking = false;

              walkingAnimGroup4.onAnimationGroupLoopObservable.add(() => {
                // Start moving the character when the animation starts
                isWalking = true;

                // Stop moving the character when the animation ends
                if (character4.position.x <= -6) {
                  isWalking = false;
                  walkingAnimGroup4.stop(); // Stop the walking animation

                  // Emit an event to signal that the character has reached its destination
                  const event = new Event('characterReachedDestination');
                  window.dispatchEvent(event);

                  // Stop rendering the scene
                  character4.dispose();
                }
              });

              // Update the character's position before each frame is rendered
              scene.registerBeforeRender(() => {
                if (isWalking) {
                  character4.position.x -= walkingSpeed;
                }
              });
            }
          });
          }
        });
      }
    }
  });
}
import React, { useEffect, useRef, useState } from 'react';
import * as BABYLON from '@babylonjs/core';
import useBabylonScene from '../hooks/useBabylonScene';
import { animateCharacter } from '../hooks/landingcharacter'; // Import the animateCharacter function

const Canvas: React.FC = () => {
  const canvasRef1 = useRef<HTMLCanvasElement | null>(null);
  const canvasRef2 = useRef<HTMLCanvasElement | null>(null);
  const [engine1, setEngine1] = useState<BABYLON.Engine | null>(null);
  const [engine2, setEngine2] = useState<BABYLON.Engine | null>(null);

  useEffect(() => {
    if (canvasRef1.current) {
      const babylonEngine = new BABYLON.Engine(canvasRef1.current, true);
      setEngine1(babylonEngine);

      window.addEventListener('resize', () => {
        babylonEngine.resize();
      });

      return () => {
        window.removeEventListener('resize', () => babylonEngine.resize());
        babylonEngine.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (canvasRef2.current) {
      const babylonEngine = new BABYLON.Engine(canvasRef2.current, true);
      setEngine2(babylonEngine);

      window.addEventListener('resize', () => {
        babylonEngine.resize();
      });

      return () => {
        window.removeEventListener('resize', () => babylonEngine.resize());
        babylonEngine.dispose();
      };
    }
  }, []);

  useBabylonScene(engine1, canvasRef1.current);

  useEffect(() => {
    if (engine2 && canvasRef2.current) {
      const scene = new BABYLON.Scene(engine2);

      // Call animateCharacter function with the scene
      animateCharacter(scene); // Animate the character in the new scene

      const renderLoopFunction = function () {
        if (!scene.isDisposed) {
          scene.render();
        }
      };

      engine2.runRenderLoop(renderLoopFunction);

      // Listen for the 'characterReachedDestination' event
      const stopRenderLoop = () => {
        engine2.stopRenderLoop(renderLoopFunction);
      };
      window.addEventListener('characterReachedDestination', stopRenderLoop);

      return () => {
        window.removeEventListener('characterReachedDestination', stopRenderLoop);
        engine2.stopRenderLoop(renderLoopFunction);
        engine2.dispose();
      };
    }
  }, [engine2, canvasRef2.current]);
  

  return (
    <div className="relative">
      <canvas ref={canvasRef1} className="w-full h-[calc(95vh-120px)] box-border z-1 outline-none bg-future-gradient2"></canvas>
      <canvas ref={canvasRef2} className="absolute top-0 left-0 w-full h-[calc(90vh-120px)] box-border z-40 outline-none"></canvas>
    </div>
  );
}

export default Canvas;

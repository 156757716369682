// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator, User } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { signInWithCustomToken as signInWithCustomTokenSDK } from 'firebase/auth';



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCuj-0urytI5DjW32zPIgrp4yFIQ8dKKkM",
  authDomain: "dahdooh.firebaseapp.com",
  projectId: "dahdooh",
  storageBucket: "dahdooh.appspot.com",
  messagingSenderId: "1062601063381",
  appId: "1:1062601063381:web:fc76c1b2d8c56b725b5b5c"
};
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Authentication
const auth = getAuth(app);
export const signInWithCustomToken = signInWithCustomTokenSDK;

// Initialize Functions
const functions = getFunctions(app);

//to export signInWithCustomToken we will write this: 

// Check if running locally (localhost)
const isLocal = window.location.hostname === "localhost";

// Use Firebase Emulator Suite when running locally
if (isLocal) {
  // Configure Firestore to use the emulator
  connectFirestoreEmulator(db, "localhost", 8080);

  // Configure Authentication to use the emulator
  connectAuthEmulator(auth, "http://localhost:9099");

  // Configure Functions to use the emulator
  connectFunctionsEmulator(functions, "localhost", 5001); // Use the correct port for your Functions emulator
}

export { analytics, db, auth, functions };
export type { User };
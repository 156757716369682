import React from 'react';

type Register3Props = {
    currentFont: string;
    setShowProfile: React.Dispatch<React.SetStateAction<boolean>>; // Function to open the Profile popup
    setShowRegister3: React.Dispatch<React.SetStateAction<boolean>>; // Function to close the Register3 popup
};

const Register3: React.FC<Register3Props> = ({ currentFont, setShowProfile, setShowRegister3 }) => {
    const handleOkClick = () => {
        setShowRegister3(false); // Close the Register3 popup
        setShowProfile(true);     // Open the Profile popup
    };
    return (
        <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 ${currentFont}`} style={{ zIndex: 10001 }}>
            <div className="bg-white w-3/5 p-6 rounded-lg text-center overflow-auto">
                <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-4">Registration Complete</h2>
                <p className="text-xs md:text-sm lg:text-base">Please check Profile in Menu to start playing the game.</p>
                <button onClick={handleOkClick} className="bg-blue-500 text-white rounded px-4 py-2">
                    OK
                </button>
            </div>
        </div>
    );
};

export default Register3;
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { useEffect, useRef } from 'react';

const useBabylonScene = (engine: BABYLON.Engine | null, canvas: HTMLCanvasElement | null) => {
  const models = useRef<BABYLON.AbstractMesh[]>([]);

  useEffect(() => {
    if (canvas && engine) {
      const babylonScene = new BABYLON.Scene(engine);
      babylonScene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

      // Switch to right-handed system
      babylonScene.useRightHandedSystem = true;

      // Camera setup
      const camera = new BABYLON.UniversalCamera("UniversalCamera", new BABYLON.Vector3(0, 3, -10), babylonScene);
      camera.setTarget(new BABYLON.Vector3(0, 0, 10));
      camera.attachControl(canvas, false); // Enable user control
      camera.inputs.remove(camera.inputs.attached.mousewheel); // Remove mouse wheel zoom
      camera.speed = 0.5; // Adjust walking speed
      camera.inertia = 0.2; // Adjust inertia for smoother movement
      camera.angularSensibility = 1000; // Adjust rotation sensitivity

      // Light setup
      const light = new BABYLON.HemisphericLight("hemiLight", new BABYLON.Vector3(0, 1, 0), babylonScene);
      light.intensity = 1;

      // Loading screen
      const loadingScreen = new BABYLON.DefaultLoadingScreen(canvas, "Loading...", "");
      engine.loadingScreen = loadingScreen;
      engine.displayLoadingUI();

      // Load reception model public\assets\models\reception.glb
      BABYLON.SceneLoader.ImportMesh("", "assets/models/", "reception.glb", babylonScene, (newMeshes) => {
        engine.hideLoadingUI(); // Hide loading UI after model is loaded

        // Create a parent for all loaded meshes
        let parent = new BABYLON.TransformNode("parent");
        newMeshes.forEach(mesh => {
          mesh.parent = parent;
        });

        // Rotate the parent 180 degrees
            parent.rotation.y = Math.PI; // Math.PI radians is 180 degrees

            // Store the loaded meshes
            models.current = newMeshes;

          // Detach user control
          camera.detachControl();

          // Camera animation
          let startPosition = new BABYLON.Vector3(0, 3, -10); // Lower the start position
          let endPosition = new BABYLON.Vector3(0, 3, 5); // Lower the end position and set z to 5
          BABYLON.Animation.CreateAndStartAnimation("cameraMove", camera, "position", 60, 1920, startPosition, endPosition, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

          // Attach user control after the animation
          setTimeout(() => {
            camera.attachControl(canvas, false);
          }, 1920 / 60 * 1000);
              });

      engine.runRenderLoop(() => {
        babylonScene.render();
      });

      return () => {
        babylonScene.dispose();
        if (engine) {
          engine.dispose();
        }
      };
    }
  }, [engine, canvas]);

  return models;
};

export default useBabylonScene;
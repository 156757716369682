import React, { useContext, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useMediaQuery } from 'react-responsive';
// import { AuthContext } from '../AuthContext'; // Commented out since Auth isn't used in the soft launch
import { FontContext } from './FontContext';
// import { deleteSessionCookie } from '../utils/cookieManager'; // Commented out since Auth isn't used in the soft launch
// import 'firebase/compat/auth'; // Commented out since Auth isn't used in the soft launch
// import { auth } from '../firebase'; // Commented out since Auth isn't used in the soft launch
// import UserContext from './misc/UserContext'; // Commented out since Auth isn't used in the soft launch
// import { User } from './types'; // Commented out since Auth isn't used in the soft launch

type HeaderProps = {
  toggleMenu: () => void;
  setShowAbout: Dispatch<SetStateAction<boolean>>;
  // setShowLogin: Dispatch<SetStateAction<boolean>>; // Commented out for soft launch
  // setShowTricks: Dispatch<SetStateAction<boolean>>; // Commented out for soft launch
  // setShowProfile: Dispatch<SetStateAction<boolean>>; // Commented out for soft launch
};

const Header: React.FC<HeaderProps> = ({ toggleMenu, setShowAbout /*, setShowLogin, setShowTricks, setShowProfile */ }) => {
  // const userContext = useContext(UserContext); // Commented out since Auth isn't used in the soft launch
  // let setUserData: Dispatch<SetStateAction<User | null>> | undefined; // Commented out since Auth isn't used in the soft launch

  // if (userContext) { // Commented out since Auth isn't used in the soft launch
  //   setUserData = userContext.setUserData;
  // }
  
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  // const authContext = useContext(AuthContext); // Commented out since Auth isn't used in the soft launch
  // const isAuthenticated = authContext?.user !== null; // Commented out since Auth isn't used in the soft launch

  const fontContext = useContext(FontContext);
  const fonts = ['pressStart', 'proxon', 'groches', 'knightwarrior']; // Font keys as per Tailwind config
  const [currentFontIndex, setCurrentFontIndex] = useState(0);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  // const handleLogout = () => { // Commented out since Auth isn't used in the soft launch
  //   auth.signOut().then(() => {
  //     deleteSessionCookie();
  //     // Clear user data
  //     if (setUserData) {
  //       setUserData(null);
  //     }
  //   });
  // };

  const changeFont = () => {
    setIsButtonClicked(true);
    setTimeout(() => setIsButtonClicked(false), 100); // Reset after 100ms
    if (fontContext && fontContext.setCurrentFont) {
      const newFontIndex = (currentFontIndex + 1) % fonts.length;
      setCurrentFontIndex(newFontIndex);
      fontContext.setCurrentFont(`font-${fonts[newFontIndex]}`);
    }
  };

  useEffect(() => {
    if (fontContext && fontContext.currentFont) {
      document.body.className = fontContext.currentFont;
    }
  }, [fontContext?.currentFont]);
  

  // const handleDesktopLoginClick = () => { // Commented out since Auth isn't used in the soft launch
  //   if (!isAuthenticated) {
  //     setShowLogin(true);
  //   }
  // };

  const handleAboutClick = () => {
    setShowAbout(true);
  };

  // const handleTricksClick = () => { // Commented out for soft launch
  //   setShowTricks(true);
  // };

  // const handleProfileClick = () => { // Commented out for soft launch
  //   setShowProfile(true);
  // };

  return (
    <nav className="bg-future-gradient text-white px-6 py-4 relative h-20">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <img src="assets/img/qqlogo.png" alt="Quality Quest Logo" className="h-12 mr-2" />
        </div>
  
        {isDesktopOrLaptop && (
          <div className="flex">
            <a href="/home" className="block ml-6">Home</a>
            <a onClick={handleAboutClick} className="block ml-6 cursor-pointer">About</a>
            {/* <a onClick={handleTricksClick} className="block ml-6 cursor-pointer">Tricks</a> // Commented out for soft launch */}
            {/* <a onClick={handleProfileClick} className="block ml-6 cursor-pointer">Profile</a> // Commented out for soft launch */}
            {/* {userContext?.firebaseUser ? ( // Commented out since Auth isn't used in the soft launch
              <button onClick={handleLogout} className="block ml-6">Logout</button>
            ) : (
              <button onClick={() => setShowLogin(true)} className="block ml-6">Login</button>
            )} */}
          </div>
        )}
  
        {isTabletOrMobile && (
          <button onClick={toggleMenu}>
            <svg 
              className="w-12 h-12" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d='M4 6h16M4 12h16M4 18h16' 
              />
            </svg>
          </button>
        )}
      </div>
      <button onClick={changeFont} className={`absolute right-0 transform translate-y-1/2 bg-gray-950 text-white px-2 py-1 z-50 ${isButtonClicked ? 'scale-90' : 'scale-100'}`}>Change Font</button>
    </nav>
  );
};

export default Header;

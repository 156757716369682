// Register2.tsx
import { User } from './types';
import React, { useState } from 'react';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Removed unnecessary 'auth' import

type Register2Props = {
  email: string;
  setShowRegister2: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRegister3: React.Dispatch<React.SetStateAction<boolean>>;
  setUserData: React.Dispatch<React.SetStateAction<User | null>>;
  setShowRegister: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  gender: string;
  currentFont: string;
  handleUserSession: (userEmail: string, token: string, user: User) => Promise<void>;
};

const Register2: React.FC<Register2Props> = ({
  email,
  setShowRegister2,
  setShowRegister3,
  setShowRegister,
  setUserData,
  name,
  gender,
  currentFont,
  handleUserSession,
}) => {
  const [otp, setOtp] = useState('');
  const [otpAttempts, setOtpAttempts] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

const handleVerify = async () => {
    const formattedEmail = email.toLowerCase();
    try {
        const response = await fetch('http://localhost:5001/dahdooh/europe-west2/verifyOTP', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: formattedEmail, otp }),
        });

        const data = await response.json();
        if (data.token) {
            const userData = { email: formattedEmail, name, gender };
            await setDoc(doc(db, "users", formattedEmail), userData);

            console.log('User data written to the database:', userData); // Log the written user data

            const userDoc = await getDoc(doc(db, "users", formattedEmail));
            if (userDoc.exists()) {
                const fetchedUserData = userDoc.data() as User;
                console.log('User data retrieved from the database:', fetchedUserData); // Log the retrieved user data

                setUserData(fetchedUserData);

                // Call handleUserSession function from App.tsx
                console.log(`Calling handleUserSession with email: ${formattedEmail} and token: ${data.token}`);
                await handleUserSession(formattedEmail, data.token, fetchedUserData);

                setShowRegister3(true);
                setShowRegister(false);
                setShowRegister2(false);
            } else {
                console.error('OTP Verification failed, no token received.');
                setOtpAttempts(otpAttempts + 1);
                setErrorMessage('Invalid OTP. Please try again.');
                if (otpAttempts >= 5) {
                    setShowRegister2(false);
                }
            }
        } else {
            console.error('OTP Verification failed, no token received.');
            setShowRegister2(false);
        }
    } catch (error) {
        console.error('Error in handleVerify:', error);
        setShowRegister2(false);
    }
};

  const handleClose = () => setShowRegister2(false);

  return (
    <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50 ${currentFont}`}>
      <div className="bg-white w-4/6 p-6 rounded relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
          onClick={handleClose}
        >
          {/* SVG icon for closing */}
        </button>
        <h2 className="text-xl font-bold mb-4">OTP Verification</h2>
        <div className="flex flex-col">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="OTP"
            className="border rounded px-4 py-2 mb-4"
          />
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button onClick={handleVerify} className="bg-blue-500 text-white rounded px-4 py-2">Verify</button>
        </div>
      </div>
    </div>
  );
};

export default Register2;

import { useEffect, useRef } from 'react';
import { ArcRotateCamera, Color4, Engine, HemisphericLight, Scene, SceneLoader, Vector3, AnimationGroup } from '@babylonjs/core';
import '@babylonjs/loaders';
import React from 'react';
import { Mesh } from '@babylonjs/core';
import { Nullable, Observer, Animation } from '@babylonjs/core';
import { StandardMaterial } from 'babylonjs';

const useCharacterAnimation = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }

        const engine = new Engine(canvasRef.current, true);
        const scene = new Scene(engine);
        scene.clearColor = new Color4(0, 0, 0, 0);

        const camera = new ArcRotateCamera('camera', Math.PI / 2 - Math.PI / 2, Math.PI / 2, 9, new Vector3(-3, +2, 0), scene);
        camera.attachControl(canvasRef.current, true);

        const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);

        const characterPath = 'assets/models/manabout3.glb';
        let currentAnimGroup: Nullable<AnimationGroup> = null;

        const createCharacter = async () => {
            try {
                const { meshes, animationGroups } = await SceneLoader.ImportMeshAsync('', '', characterPath, scene);
                const characterMesh = meshes[0] as Mesh;

                const animations = {
                    'walking': animationGroups.find(group => group.name.toLowerCase() === 'walking'),
                    'turningright': animationGroups.find(group => group.name.toLowerCase() === 'turningright'),
                    'waving': animationGroups.find(group => group.name.toLowerCase() === 'waving'),
                    'clapping': animationGroups.find(group => group.name.toLowerCase() === 'clapping'),
                    'happyidle': animationGroups.find(group => group.name.toLowerCase() === 'happyidle'),
                };

                type AnimationKeys = keyof typeof animations;
                const animationSequence: AnimationKeys[] = ['walking', 'turningright', 'waving', 'clapping', 'happyidle'];
                let currentAnimationIndex = 0;

                // Function to handle blending between animations
                const blendAnimations = (fromAnimGroup: Nullable<AnimationGroup>, toAnimGroup: AnimationGroup) => {
                    if (fromAnimGroup) {
                        fromAnimGroup.stop();
                    }
                    toAnimGroup.start(false, 1.0, toAnimGroup.from, toAnimGroup.to, false);
                };

                let walkLoopCount = 0; // Add this line

                const playNextAnimation = () => {
                    const nextAnimName: AnimationKeys = animationSequence[currentAnimationIndex];
                    const nextAnimGroup = animations[nextAnimName];
                
                    if (nextAnimGroup) {
                        // Stop all animations before starting the next one
                        scene.stopAllAnimations();
                
                        blendAnimations(currentAnimGroup, nextAnimGroup);
                        currentAnimGroup = nextAnimGroup;
                
                        // Handling specific animations
                        if (nextAnimName === 'walking') {
                            const moveSpeed = 0.01;
                            let moveObservable: Nullable<Observer<Scene>> = null;
                
                            moveObservable = scene.onBeforeRenderObservable.add(() => {
                                characterMesh.position.z += moveSpeed;
                            });
                
                            nextAnimGroup.onAnimationEndObservable.addOnce(() => {
                                if (moveObservable) {
                                    scene.onBeforeRenderObservable.remove(moveObservable);
                                }
                
                                walkLoopCount++; // Increment the counter each time the walking animation ends
                
                                // Only move to the next animation when the counter reaches 4
                                if (walkLoopCount >= 4) {
                                    walkLoopCount = 0; // Reset the counter
                                    currentAnimationIndex = (currentAnimationIndex + 1) % animationSequence.length;
                                    playNextAnimation();
                                } else {
                                    // If the counter hasn't reached 4, restart the walking animation
                                    nextAnimGroup.start(true);
                                }
                            });
                        }

                        if (nextAnimName === 'waving') {
                            console.log('Waving animation started');
                            characterMesh.rotationQuaternion = null;
                            characterMesh.rotation.y += -Math.PI / 2;
                        }

                        nextAnimGroup.onAnimationEndObservable.addOnce(() => {
                            currentAnimationIndex = (currentAnimationIndex + 1) % animationSequence.length;
                            if (currentAnimationIndex !== 0) {
                                playNextAnimation();
                            } else {
                                // If it's the last animation, make it loop
                                nextAnimGroup.loopAnimation = true;
                            }
                        });

                        // If it's the 'happyidle' animation, loop it explicitly
                        // If it's the 'happyidle' animation, loop it explicitly and fade out the character at the end


                        if (nextAnimName === 'happyidle') {
                            nextAnimGroup.loopAnimation = true;

                            nextAnimGroup.onAnimationLoopObservable.addOnce(() => {
                                // Delay the start of the fade out animation by 3 seconds
                                setTimeout(() => {
                                    // Create a new animation
                                    const disappearAnimation = new Animation(
                                        'disappearAnimation',
                                        'scaling',
                                        60, // Frame rate: 60 FPS
                                        Animation.ANIMATIONTYPE_VECTOR3,
                                        Animation.ANIMATIONLOOPMODE_CONSTANT
                                    );

                                    // Add animation keys
                                    const keys = [];
                                    keys.push({ frame: 0, value: characterMesh.scaling });
                                    keys.push({ frame: 300, value: new Vector3(0, 0, 0) }); // 300 frames at 60 FPS = 5 seconds
                                    disappearAnimation.setKeys(keys);

                                    // Apply the animation to the character mesh
                                    characterMesh.animations.push(disappearAnimation);

                                    // Run the animation
                                    scene.beginAnimation(characterMesh, 0, 600, false);
                                }, 7000); // 3 seconds
                            });
                        }

                        // Start the next animation
                        nextAnimGroup.start(true);
                    }
                };

                playNextAnimation();
            } catch (error) {
                console.error('Failed to load character:', error);
            }
        };

        createCharacter();

        engine.runRenderLoop(() => scene.render());
        return () => {
            engine.dispose();
            if (currentAnimGroup) {
                currentAnimGroup.stop();
            }
        };
    }, [canvasRef]);

    return null;
};

export default useCharacterAnimation;

import React, { useState, useEffect, useRef } from 'react';
import { useTypingEffect } from '../../hooks/useTypingEffect';
import useCharacterAnimation from '../../hooks/useCharacterAnimation';

type AboutProps = {
  setShowAbout: React.Dispatch<React.SetStateAction<boolean>>;
  currentFont: string;
};

const About: React.FC<AboutProps> = ({ setShowAbout, currentFont }) => {
  const handleClose = () => {
    setShowAbout(false);
  };

  const typingTexts = [
    "Thank you for visiting my project—an online game dedicated to healthcare quality education. It is a changing landscape 🌱, with the anticipation of implementing AI and other technologies 🤖.",

    "The project is a work in progress, a modest attempt to comprehend the role of these technologies in the future of healthcare. It's a journey 🚀 in which each step is led by curiosity, with no high claims or guarantees 🌟.",
    
    "Please keep in mind that this project is still in its early stages, and the future is often shaped by our efforts 💪.",
    
    "It's a chance for us to explore 🌍, learn 📚, and grow together as we discover the opportunities 🌈.",
    
    "Please contact us if you are willing to assist. Beta testers 🧪 will be needed shortly. Developers with varied levels of skills 💼 will be invaluable.",

  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [completedTexts, setCompletedTexts] = useState<string[]>([]);
  const text = useTypingEffect(typingTexts[currentTextIndex], 100);

  const [isTypingCompleted, setIsTypingCompleted] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useCharacterAnimation(canvasRef);

  useEffect(() => {
    if (!isTypingCompleted && text === typingTexts[currentTextIndex]) {
      if (completedTexts[completedTexts.length - 1] !== text) {
        setCompletedTexts((texts) => [...texts, text]);
      }
      if (currentTextIndex < typingTexts.length - 1) {
        setCurrentTextIndex((index) => index + 1);
      } else {
        setIsTypingCompleted(true);
      }
    }
  }, [text, typingTexts, currentTextIndex, isTypingCompleted, completedTexts]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className={`bg-future-secondary w-4/5 h-4/5 p-6 rounded relative ${currentFont}`}>
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 p-2 focus:outline-none"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={3}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4">About</h2>
        <div className="flex flex-col items-center text-center text-xl space-y-4 p-20">
          {completedTexts.map((completedText, index) => (
            <p key={index}>{completedText}</p>
          ))}
          {!isTypingCompleted && <p>{text}</p>}
        </div>
      </div>
      <canvas ref={canvasRef} className="absolute bottom-0 left-0 w-full h-3/4 z-60" />
    </div>
  );
  
};

export default About;
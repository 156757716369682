import React from 'react';

type TitleBandProps = {
  currentFont: string;
};

const TitleBand: React.FC<TitleBandProps> = ({ currentFont }) => {
  return (
<div
  className={`${currentFont} text-future-primary bg-future-overlay bg-opacity-70`}
  style={{
    width: '100%',
    height: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
  }}
>
  <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl m-0 p-0">
    Quest in Quality
  </h1>
</div>


  );
}

export default TitleBand;
import React, { useEffect, useState, useContext } from 'react';
// import { AuthContext } from '../AuthContext'; // Commented out as Auth isn't used in the soft launch
import { FontContext } from './FontContext';
// import { deleteSessionCookie } from '../utils/cookieManager'; // Commented out as Auth isn't used in the soft launch
// import 'firebase/compat/auth'; // Commented out as Auth isn't used in the soft launch
// import { auth } from '../firebase'; // Commented out as Auth isn't used in the soft launch

type MenuProps = {
  toggleMenu: () => void;
  setShowAbout: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowTricks: React.Dispatch<React.SetStateAction<boolean>>; // Commented out for soft launch
  // setShowProfile: React.Dispatch<React.SetStateAction<boolean>>; // Commented out for soft launch
  // setShowLogin: React.Dispatch<React.SetStateAction<boolean>>; // Commented out for soft launch
};

const Menu: React.FC<MenuProps> = ({ toggleMenu, setShowAbout /*, setShowTricks, setShowProfile, setShowLogin*/ }) => {
  const [rightPosition, setRightPosition] = useState('-100%');
  const fontContext = useContext(FontContext); // Use FontContext
  // const authContext = useContext(AuthContext); // Commented out as Auth isn't used in the soft launch
  // const isAuthenticated = authContext?.user !== null; // Commented out as Auth isn't used in the soft launch

  useEffect(() => {
    setRightPosition('0');
  }, []);

  const handleClose = () => {
    setRightPosition('-100%');
    setTimeout(toggleMenu, 300); 
  };

  const handleAboutClick = () => {
    setShowAbout(true);
    toggleMenu();
  };

  // const handleTricksClick = () => { // Commented out for soft launch
  //   setShowTricks(true);
  //   toggleMenu();
  // };

  // const handleProfileClick = () => { // Commented out for soft launch
  //   setShowProfile(true);
  //   toggleMenu();
  // };

  return (
    <div 
      style={{
        width: '90%', 
        right: rightPosition, 
        backgroundColor: 'rgba(211, 211, 211, 0.9)', 
        zIndex: 101,
      }} 
      className={`fixed top-0 right-0 h-full transition-all duration-300 ease-in-out text-3xl ${fontContext?.currentFont}`}
    >
      <div className="px-6 py-8 flex flex-col items-center justify-center">
        <button onClick={handleClose} className="self-start mb-8">
          X {/* Replace this with your SVG or icon */}
        </button>
        <a href="/home" className="block text-center my-8">Home</a>
        <div className="border-b-2 border-gray-400 w-full my-8"></div>
        <a onClick={handleAboutClick} className="block ml-6 cursor-pointer">About</a>
        {/* Commented out Tricks and Profile links as they are not used in the soft launch */}
        {/* <div className="border-b-2 border-gray-400 w-full my-8"></div>
        <a onClick={handleTricksClick} className="block ml-6 cursor-pointer">Tricks</a>
        <div className="border-b-2 border-gray-400 w-full my-8"></div>
        <a onClick={handleProfileClick} className="block ml-6 cursor-pointer">Profile</a> */}
      </div>
    </div>
  );
};

export default Menu;

//App.tsx
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from './components/Header';
import Canvas from './components/Canvas';
import TitleBand from './components/TitleBand';
import Menu from './components/Menu';
import { AuthProvider } from './AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import About from './components/misc/About';
import Tricks from './components/misc/Tricks';
import Profile from './components/misc/Profile';
import Register3 from './components/Register3';
import { FontContext } from './components/FontContext';
import './index.css';
import Login3 from './components/Login3';
import Login2 from './components/Login2';
import UserContext, { UserContextType } from './components/misc/UserContext';
import { User } from './components/types';
import { setSessionCookie, getSessionCookie, deleteSessionCookie } from './utils/cookieManager';
import { auth, User as FirebaseUser } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { signInWithCustomToken } from 'firebase/auth';
import Level1 from './user/Level1';
import Level2 from './user/Level2';
import SceneManager from './user/scenes/SceneManager';
import Scene1_Level1 from './user/scenes/level1/Scene1_Level1';
import { getLevelComponent, getSceneComponent } from './user/scenes/levelMapping';

const App: React.FC = () => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
  const [userData, setUserData] = useState<User | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showTricks, setShowTricks] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showRegister3, setShowRegister3] = useState(false);
  const [currentFont, setCurrentFont] = useState('font-proxon');
  const [showLogin3, setShowLogin3] = useState(false);
  const [showLogin2, setShowLogin2] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

// Keep these lines
const [currentLevel, setCurrentLevel] = useState<number | null>(null);
const [currentScene, setCurrentScene] = useState<number | null>(null);
const [showLevel, setShowLevel] = useState<number | null>(null);
const [showScene, setShowScene] = useState<number | null>(null);


const openLevel = (level: number) => {
  console.log(`Opening App.tsx level ${level}`);
  setShowLevel(level);
};


const openScene = (scene: number) => {
  setShowScene(scene);
};

const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
const handleRegisterLinkClick = () => {
  setShowLogin(false);
  setShowRegister(true);
};

// State to track whether user data has been fetched
const [isUserDataFetched, setIsUserDataFetched] = useState(false);

// Function to set session cookie
const setSessionCookie = (token: string) => {
  // Set session expiration as 1 hour from current time
  const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = `session_token=${token};expires=${expires};path=/`;
  console.log(`Session cookie set with token: ${token}`);
};

// Function to delete session cookie
const deleteSessionCookie = () => {
  document.cookie = 'session_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
  console.log('Session cookie deleted.');
};

const setupAuthStateObserver = () => {
  console.log('Setting up the auth state observer.');
  const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
    console.log(`User state changed: `, user);
    if (user) {
      const token = await user.getIdToken();
      console.log(`User ID token received: ${token}`);
      setSessionCookie(token);
      setFirebaseUser(user);
    } else {
      console.log('No user is logged in.');
      deleteSessionCookie();
      setFirebaseUser(null);
      setUserData(null);
    }
  });
  return unregisterAuthObserver;
};

const handleUserSession = async (userEmail: string, token: string, user: User) => {
  console.log(`Handling user session for email: ${userEmail} with token: ${token}`);
  setSessionCookie(token);
  
  // Sign in the user with the custom token
  await signInWithCustomToken(auth, token);
  
  setupAuthStateObserver();

  // Store user data in localStorage
  localStorage.setItem('user', JSON.stringify(user));
  console.log('Stored user data:', localStorage.getItem('user'));
};

// Effect to setup and teardown the authentication observer
useEffect(() => {
  console.log('Setting up auth observer in useEffect.');
  const unregisterAuthObserver = setupAuthStateObserver();
  return () => {
    console.log('Unsubscribing from auth observer.');
    unregisterAuthObserver();
  };
}, []);

  useEffect(() => {
    const handleLoginClick = () => setShowLogin(true);
    window.addEventListener('mobileMenuLoginClicked', handleLoginClick);
    return () => window.removeEventListener('mobileMenuLoginClicked', handleLoginClick);
  }, []);

  useEffect(() => {
    console.log(`The current state of showLogin3 is: ${showLogin3}`);
  }, [showLogin3]);

  const userContextValue: UserContextType = {
    userData: userData,
    setUserData,
    firebaseUser, // Include firebaseUser in the context
    setFirebaseUser // Include setFirebaseUser in the context
  };

  const LevelComponent = showLevel !== null ? React.lazy(() => import(`./user/Level${showLevel}`)) : null;
  const SceneComponent = showScene !== null ? React.lazy(() => import(`./user/scenes/Level${showLevel}/Scene${showScene}_Level${showLevel}`)) : null;


  return (
    <UserContext.Provider value={userContextValue}>
      <AuthProvider>
        <FontContext.Provider value={{ currentFont, setCurrentFont }}>
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header toggleMenu={toggleMenu}  setShowAbout={setShowAbout}  />
            {isTabletOrMobile && isMenuOpen && <Menu toggleMenu={toggleMenu} setShowAbout={setShowAbout} />}
            
            {showProfile && <Profile setShowProfile={setShowProfile} showLevel={showLevel} setShowLevel={setShowLevel} currentFont={currentFont} openLevel={openLevel} />}
          {showAbout && <About setShowAbout={setShowAbout} currentFont={currentFont} />}
          {showTricks && <Tricks setShowTricks={setShowTricks} currentFont={currentFont} />}

          <React.Suspense fallback={<div>Loading level...</div>}>
            {showLevel !== null && LevelComponent && <LevelComponent showLevel={showLevel} setShowLevel={setShowLevel} currentFont={currentFont} setShowProfile={setShowProfile} openScene={openScene} />}
          </React.Suspense>

          <React.Suspense fallback={<div>Loading scene...</div>}>
            {showScene !== null && SceneComponent && <SceneComponent showScene={showScene} setShowScene={setShowScene} currentFont={currentFont} setShowProfile={setShowProfile} />}
          </React.Suspense>
  
            {showRegister3 && <Register3 currentFont={currentFont} setShowProfile={setShowProfile} setShowRegister3={setShowRegister3} />}
            <TitleBand currentFont={currentFont} />
            <Canvas />
            <div className="flex-shrink-0 text-center bg-future-gradient text-white p-4 max-h-[10vh]">
              <div className="text-lg md:text-l lg:text-xl">
                &copy; 2023 Quest in Quality - under development.
              </div>
              <div className="text-xs md:text-sm lg:text-base">
                For more information, contact <a href="https://www.linkedin.com/in/drmasad/" className="underline">Dr. M. As'ad</a>.
              </div>
            </div>
          </div>


          {showLogin && <Login handleUserSession={handleUserSession} showLogin3 setShowLogin={setShowLogin} onRegisterLinkClick={handleRegisterLinkClick} currentFont={currentFont} setUserData={setUserData}setShowLogin3={setShowLogin3}/>}
          {showLogin3 && <Login3 setShowLogin3={setShowLogin3} setShowProfile={setShowProfile} currentFont={currentFont} />}
          {showRegister && <Register handleUserSession={handleUserSession} setShowRegister={setShowRegister} setShowRegister3={setShowRegister3} currentFont={currentFont} setupAuthStateObserver={setupAuthStateObserver } isUserDataFetched={isUserDataFetched}/>}
        </FontContext.Provider>
      </AuthProvider>
    </UserContext.Provider>
  );
  
};

export default App;

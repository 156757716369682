import React from 'react';

type Login3Props = {
    setShowLogin3: React.Dispatch<React.SetStateAction<boolean>>;
    setShowProfile: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
    currentFont: string;
};

const Login3: React.FC<Login3Props> = ({ setShowLogin3, setShowProfile, currentFont }) => {
    const handleOk = () => {
        setShowLogin3(false); // Hide Login3
        setShowProfile(true); // Show Profile (if this is desired upon clicking OK)
    };

    return (
        <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 ${currentFont}`} style={{ zIndex: 55 }}>
            <div className="bg-white w-3/5 p-6 rounded-lg text-center overflow-auto">
                <h2 className="text-lg md:text-xl lg:text-2xl font-bold mb-4">Login Complete</h2>
                <p className="text-xs md:text-sm lg:text-base">You are now logged in. Please check Profile in Menu to start playing the game.</p>
                <button
                    onClick={handleOk}
                    className="bg-blue-500 text-white rounded px-4 py-2 mt-4"
                >
                    OK
                </button>
            </div>
        </div>
    );
};

export default Login3;